var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"id":"Groups","fluid":"","tag":"section"}},[_c('v-overlay',{attrs:{"z-index":"60"},model:{value:(_vm.loaderlay),callback:function ($$v) {_vm.loaderlay=$$v},expression:"loaderlay"}},[_c('v-progress-circular',{staticClass:"l2",attrs:{"indeterminate":"","size":"100","color":"primary"}},[_c('v-progress-circular',{staticClass:"l3",attrs:{"indeterminate":"","size":"75","color":"primary"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-card',{staticClass:"v-card--material true pa-3"},[_c('card-heading',{attrs:{"color":_vm.sectionColor,"title":'Players'}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"must-sort":"","sort-by":"player_mongo_id","sort-desc":true,"headers":_vm.headers,"items":_vm.list,"item-class":_vm.itemClass,"options":_vm.options,"server-items-length":_vm.total_records,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"top",fn:function({ pagination }){return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('v-form',{ref:"search",staticClass:"col-3 ml-6 pa-0",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"pa-0",attrs:{"align":"center"}},[_c('v-tooltip',{attrs:{"color":"primary darken-2","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"large","color":`primary ${
														_vm.$vuetify.theme.dark ? '' : 'darken-2'
													}`}},[_vm._v(" mdi-help-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Search Key can be either: "),_c('br'),_vm._v(" Player Nick or Internal Player ID ")])]),(
											_vm._usrFlagsSome({
												key: `player.detail`,
												val: _vm.permissions.READ,
											})
										)?_c('v-text-field',{attrs:{"label":"Search","dark":_vm.$vuetify.theme.dark,"light":!_vm.$vuetify.theme.dark,"error-messages":_vm.searchErrs,"rules":_vm.searchRules},on:{"blur":() => {
												_vm.valid = true;
												(_vm.searchRules = []), (_vm.searchErrs = []);
											},"input":() => {
												_vm.valid = true;
												(_vm.searchRules = []), (_vm.searchErrs = []);
											},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return ((e) =>
												_vm.onSubmit(
													_vm.searchKey,
													_vm.loadPlayerSearch,
													_vm.playerSearch,
													_vm.handleClick,
													e
												)).apply(null, arguments)}},model:{value:(_vm.searchKey),callback:function ($$v) {_vm.searchKey=$$v},expression:"searchKey"}}):_vm._e()],1)],1),_c('v-data-footer',_vm._b({staticClass:"anton",attrs:{"options":_vm.options,"pagination":pagination},on:{"update:options":function($event){_vm.options=$event}}},'v-data-footer',_vm.footerProps,false))],1)]}},{key:`item.sum_bet`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("Number")(item.sum_bet,{ currency: item.currency }))+" ")]}},{key:`item.sum_win`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("Number")(item.sum_win,{ currency: item.currency }))+" ")]}}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }